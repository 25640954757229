import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { BaseService } from "app/shared/classes/BaseService";

@Injectable({
  providedIn: "root",
})
export class InscripcionesService extends BaseService {
  constructor(public http: HttpClient, public globals: Globals) {
    super(http, globals);
  }

  getAll(filters?: any) {
    return this.http.get<any[]>(`${this.globals.apiUrl}/${this.route}/getAll`, {
      params: { filters: JSON.stringify(filters || {}) },
    });
  }

  cancelar(id: number) {
    return this.http.patch(
      `${this.globals.apiUrl}/${this.route}/cancelar/${id}`,
      {}
    );
  }

  reactivar(id: number) {
    return this.http.patch(
      `${this.globals.apiUrl}/${this.route}/reactivar/${id}`,
      {}
    );
  }

  public model: string = "inscripcion";
  public route: string = "inscripciones";
}
