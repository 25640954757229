import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateInscripcionComponent } from "./create-inscripcion.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";

@NgModule({
  declarations: [CreateInscripcionComponent],
  imports: [
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
    CommonModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    ToastrModule,
    NgSelectModule,
    JwBootstrapSwitchNg2Module,
    BsDatepickerModule,
  ],
})
export class CreateInscripcionModule {}
