import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { finalize, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReportesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  alumnos(data: any) {
    return this.http.post<any>(`${this.globals.apiUrl}/reportes/alumnos`, {
      ...data,
    });
  }
  inscripciones(data: any) {
    return this.http.post<any>(
      `${this.globals.apiUrl}/reportes/inscripciones`,
      {
        ...data,
      }
    );
  }
  pagos(data: any) {
    return this.http.post<any>(`${this.globals.apiUrl}/reportes/pagos`, {
      ...data,
    });
  }
  getPagosAlumnosReport(data: any) {
    return this.http.post<any>(
      `${this.globals.apiUrl}/reportes/getPagosAlumnosReport`,
      {
        ...data,
      }
    );
  }
  alumnosExcel(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Accept",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    return this.http
      .post(
        `${this.globals.apiUrl}/reportes/alumnosExcel`,
        { ...data },
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .pipe(map((file) => this.openFile(file)));
  }
  inscripcionesExcel(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Accept",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    return this.http
      .post(
        `${this.globals.apiUrl}/reportes/inscripcionesExcel`,
        { ...data },
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .pipe(map((file) => this.openFile(file)));
  }

  alumnosPagosExcel() {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Accept",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    return this.http
      .get(`${this.globals.apiUrl}/reportes/alumnosPagosExcel`, {
        // headers: headers,
        responseType: "blob",
      })
      .pipe(map((file) => this.openFile(file)));
  }

  pagosExcel(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Accept",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    return this.http
      .post(
        `${this.globals.apiUrl}/reportes/pagosExcel`,
        { ...data },
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .pipe(map((file) => this.openFile(file)));
  }

  ticketRptPagos(filters: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    return this.http
      .get<any>(`${this.globals.apiUrl}/reportes/ticketRptPagos`, {
        headers,
        responseType: <any>"blob",
        params: { filters },
      })
      .pipe(
        switchMap((blob: Blob) => {
          var downloadURL = URL.createObjectURL(blob);
          window.open(downloadURL);
          return of(blob);
        })
      );
  }

  private openFile(file) {
    const blob = new Blob([file], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    var fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
    return of(file);
  }
}
