import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { LoginComponent } from "./components/login/login.component";
import { AuthGuard } from "./auth.guard";
import { NgxPermissionsGuard } from "ngx-permissions";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./components/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "asistencias",
        loadChildren:
          "./components/asistencias/asistencias.module#AsistenciasModule",
      },
      {
        path: "configuracion",
        loadChildren: "./components/config/config.module#ConfigModule",
      },
      {
        path: "catalogos",
        loadChildren: "./components/catalogos/catalogos.module#CatalogosModule",
      },
      // {
      //   path: "inscripciones",
      //   loadChildren:
      //     "./components/inscripciones/inscripciones.module#InscripcionesModule",
      // },
      {
        path: "reportes",
        loadChildren: "./components/reportes/reportes.module#ReportesModule",
      },
      {
        path: "pagos",
        loadChildren: "./components/pagos/pagos.module#PagosModule",
        canActivate: [NgxPermissionsGuard, AuthGuard],
        data: {
          permissions: {
            only: "PAGOS",
            redirectTo: "/dashboard",
          },
        },
      },
    ],
  },
];
