import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateAlumnoComponent } from "./create-alumno.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgSelectModule } from "@ng-select/ng-select";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { FileUploadModule } from "ng2-file-upload";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

@NgModule({
  declarations: [CreateAlumnoComponent],
  imports: [
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBorderRadius: "3px",
    }),
    CommonModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    ToastrModule,
    NgSelectModule,
    JwBootstrapSwitchNg2Module,
    FileUploadModule,
    BsDatepickerModule,
  ],
})
export class CreateAlumnoModule {}
