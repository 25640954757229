import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { BaseService } from "app/shared/classes/BaseService";

@Injectable({
  providedIn: "root",
})
export class GruposService extends BaseService {
  constructor(public http: HttpClient, public globals: Globals) {
    super(http, globals);
  }
  public model: string = "grupo";
  public route: string = "grupos";

  manageInscripciones({ toAdd, toUpdate, grupo }) {
    return this.http.post<any>(
      `${this.globals.apiUrl}/${this.route}/manageInscripciones`,
      {
        toAdd,
        toUpdate,
      }
    );
  }
  changeGroup(data) {
    return this.http.post<any>(
      `${this.globals.apiUrl}/${this.route}/changeGroup`,
      {
        data
      }
    );
  }
}
