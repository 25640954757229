import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { AlumnosService } from "app/services/alumnos.service";
import { GruposService } from "app/services/grupos.service";
import { InscripcionesService } from "app/services/inscripciones.service";
import { PaquetesService } from "app/services/paquetes.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { defineLocale, esLocale } from "ngx-bootstrap/chronos";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, concat, of } from "rxjs";
import {
  switchMap,
  takeUntil,
  finalize,
  debounceTime,
  distinctUntilChanged,
  tap,
  catchError,
} from "rxjs/operators";
defineLocale("es", esLocale);

export const CREATE_INSCRIPCION_MODAL = "CREATE_INSCRIPCION";

@Component({
  selector: "app-create-inscripcion",
  templateUrl: "./create-inscripcion.component.html",
  styleUrls: ["./create-inscripcion.component.css"],
})
export class CreateInscripcionComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  public create$ = () =>
    of({}).pipe(
      switchMap(() => {
        if (this.form.invalid) throw "Formulario inválido.";
        this.requesting = true;
        return this._inscripciones.add({
          ...this.form.value,
          alumno: this.data.id,
        });
      }),
      takeUntil(this.onDestroy$),
      finalize(() => (this.requesting = false))
    );

  //CONFIG
  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-default",
    dateInputFormat: "DD/MM/YYYY",
  };

  //STATUS
  public loading: boolean;
  public requesting: boolean;

  //DATA
  public data: any;
  public sexos = ["Masculino", "Femenino"];
  public paquetes: any[] = [];

  public grupos$: any;
  public gruposLoading = false;
  public gruposSearch$ = new Subject<string>();
  //FORMS
  public form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _locale: BsLocaleService,
    private _inscripciones: InscripcionesService,
    private _alumnos: AlumnosService,
    private _grupos: GruposService,
    private _paquetes: PaquetesService
  ) {}

  get disabled() {
    return this.form.invalid || this.requesting;
  }

  ngOnInit() {
    this.initForm();
    this._locale.use("es");
    this.initListeners();
    this.getData();
    this.getPaquetes();
    this.getGrupos();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForm() {
    this.form = this._fb.group({
      fecha: [null, [Validators.required]],
      grupo: [null, [Validators.required]],
      paquete: [null, [Validators.required]],
    });
  }

  initListeners() {}

  //REQ DATA
  getData() {
    const { id } = this._modal.get(CREATE_INSCRIPCION_MODAL).getData();
    if (!id) return;
    this.loading = true;
    this._alumnos
      .get({ where: { id } })
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        ([data]) => {
          this.data = data;
          this.form.patchValue(data);
        },
        (_) => this._toast.errorToastr("No se encontró el alumno")
      );
  }

  getPaquetes() {
    this._paquetes
      .get({ status: "activo" })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((paquetes: any[]) => (this.paquetes = paquetes));
  }

  getGrupos(init: any[] = []) {
    this.grupos$ = concat(
      of(init), // default items
      this.gruposSearch$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => (this.gruposLoading = true)),
        switchMap((contains) =>
          this._grupos
            .get({
              where: {
                nombre: { contains },
                status: "activo",
                lleno: false,
              },
              sort: "nombre ASC",
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.gruposLoading = false))
            )
        )
      )
    );
  }

  onSubmit() {
    this.create();
  }

  create() {
    this.create$().subscribe(
      (data) => {
        this._toast.successToastr("Inscripción creada correctamente");
        this.close(data);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  close(created?: any) {
    this._modal
      .get(CREATE_INSCRIPCION_MODAL)
      .removeData()
      .setData({ created })
      .close();
  }

  //UTILS
  invalidControl(control: AbstractControl) {
    if (!control) return;
    return control.invalid && control.touched;
  }
}
