import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  apiUrl: string = "https://apiacademia.pioneroslm.com";
  permisosdisponibles = [
    "ALUMNOS",
    "ASISTENCIAS",
    "CICLOS",
    // "CONTACTOS",
    "DASHBOARD",
    "ENTRENADORES",
    "GRUPOS",
    // "INSCRIPCIONES",
    "PAGOS",
    "PAQUETES",
    "PATROCINADORES",
    "REPORTE ALUMNOS",
    "REPORTE INSCRIPCIONES",
    "REPORTE PAGOS",
    "EDITAR PAGOS",
    "TUTORES",
    "USUARIOS",
  ];
  paises = ["México"];
  metodosPago = [
    { value: "efectivo", label: "Efectivo" },
    { value: "tarjeta", label: "Tarjeta" },
    { value: "credito", label: "Crédito comercial" },
    { value: "transferencia", label: "Transferencia" },
    { value: "patrocinio", label: "Patrocinio" },
  ];
  tiposUsuario = [
    { value: "usuario", label: "Usuario" },
    { value: "entrenador", label: "Entrenador" },
    { value: "admin", label: "Administrador" },
  ];
}
