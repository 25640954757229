import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";
import { BaseService } from "app/shared/classes/BaseService";

@Injectable({
  providedIn: "root",
})
export class TutoresService extends BaseService {
  constructor(public http: HttpClient, public globals: Globals) {
    super(http, globals);
  }
  public model: string = "tutor";
  public route: string = "tutores";
}
