import { Component, OnInit } from "@angular/core";

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Inicio",
    type: "link",
    icontype: "nc-icon nc-bank",
    permission: "DASHBOARD",
  },
  {
    path: "/asistencias",
    title: "Asistencias",
    type: "link",
    icontype: "fal fa-list-alt",
    permission: "ASISTENCIAS",
  },
  // {
  //   path: "/inscripciones",
  //   title: "Inscripciones",
  //   type: "link",
  //   icontype: "fal fa-clipboard-user",
  //   permission: "INSCRIPCIONES",
  // },
  {
    path: "/pagos",
    title: "Pagos",
    type: "link",
    icontype: "fal fa-money-bill-alt",
    permission: "PAGOS",
  },
  {
    path: "/reportes",
    title: "Reportes",
    type: "sub",
    icontype: "fal fa-chart-bar",
    children: [
      {
        path: "alumnos",
        title: "Alumnos",
        icontype: "fal fa-user-graduate",
        permission: "REPORTE ALUMNOS",
      },
      {
        path: "inscripciones",
        title: "Inscripciones",
        icontype: "fal fa-money-check-edit",
        permission: "REPORTE INSCRIPCIONES",
      },
      {
        path: "pagos",
        title: "Pagos",
        icontype: "fal fa-money-bill-alt",
        permission: "REPORTE PAGOS",
      },
    ],
  },
  {
    path: "/catalogos",
    title: "Catálogos",
    type: "sub",
    icontype: "fal fa-archive",
    children: [
      {
        path: "alumnos",
        title: "Alumnos",
        icontype: "fal fa-user-graduate",
        permission: "ALUMNOS",
      },
      {
        path: "ciclos",
        title: "Ciclos",
        icontype: "fal fa-clock",
        permission: "CICLOS",
      },
      // {
      //   path: "contactos",
      //   title: "Contactos",
      //   icontype: "fal fa-address-book",
      //   permission: "CONTACTOS",
      // },
      {
        path: "entrenadores",
        title: "Entrenadores",
        icontype: "fal fa-chalkboard-teacher",
        permission: "ENTRENADORES",
      },
      {
        path: "grupos",
        title: "Grupos",
        icontype: "fal fa-users-class",
        permission: "GRUPOS",
      },
      {
        path: "paquetes",
        title: "Paquetes",
        icontype: "fal fa-box",
        permission: "PAQUETES",
      },
      {
        path: "patrocinadores",
        title: "Patrocinadores",
        icontype: "fal fa-badge-dollar",
        permission: "PATROCINADORES",
      },
      {
        path: "tutores",
        title: "Tutores",
        icontype: "fal fa-user-tie",
        permission: "TUTORES",
      },
      {
        path: "usuarios",
        title: "Usuarios",
        icontype: "fal fa-users",
        permission: "USUARIOS",
      },
    ],
  },
  {
    path: "/configuracion",
    title: "Configuración",
    type: "link",
    icontype: "fas fa-cog",
    permission: "CONFIGURACION",
  },
];

@Component({
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  private token = JSON.parse(localStorage.getItem("jwtToken"));
  public perm = this.token.user.permisos;
  public user = this.token.user;

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
}
