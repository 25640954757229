import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";

import { SidebarModule } from "./shared/sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";
import { JwtInterceptor } from "./http.interceptor";
import { ErrorInterceptor } from "./error.interceptor";
import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./components/login/login.component";
import { Globals } from "./globals";
// no son de la plantilla
import { AngularTokenModule } from "angular-token";
import { NgxPermissionsModule } from "ngx-permissions";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgSelectModule } from "@ng-select/ng-select";
import { FileUploadModule } from "ng2-file-upload";
import { AgmCoreModule } from "@agm/core";

import localeMX from "@angular/common/locales/es-MX";
import { registerLocaleData } from "@angular/common";
import { CreateAlumnoModule } from "./components/catalogos/alumnos/components/create-alumno/create-alumno.module";
import { CreateAlumnoComponent } from "./components/catalogos/alumnos/components/create-alumno/create-alumno.component";
import { CreateTutorModule } from "./components/catalogos/tutores/components/create-tutor/create-tutor.module";
import { CreateTutorComponent } from "./components/catalogos/tutores/components/create-tutor/create-tutor.component";
import { CreateInscripcionModule } from "./components/catalogos/alumnos/components/create-inscripcion/create-inscripcion.module";
import { CreateInscripcionComponent } from "./components/catalogos/alumnos/components/create-inscripcion/create-inscripcion.component";
registerLocaleData(localeMX, "es-MX");

const APP_MODAL_MODULES = [
  CreateAlumnoModule,
  CreateTutorModule,
  CreateInscripcionModule,
];

const APP_MODAL_ENTRIES = [
  CreateAlumnoComponent,
  CreateTutorComponent,
  CreateInscripcionComponent,
];
@NgModule({
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    NgbModule.forRoot(),
    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiBase: "https://apiacademia.pioneroslm.com",
      apiPath: null,
      signInPath: "auth/login",
      signInRedirect: "/dashboard",
      signInStoredUrlStorageKey: "token_academia_pioneros",
      signOutPath: "/",
      loginField: "usuario",
    }),
    AgmCoreModule.forRoot({
      apiKey: "",
    }),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    NgxDatatableModule,
    NgxSmartModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    FileUploadModule,
    ...APP_MODAL_MODULES,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
  ],
  bootstrap: [AppComponent],
  entryComponents: [...APP_MODAL_ENTRIES],
  providers: [
    Globals,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "es-MX" },
  ],
})
export class AppModule {}
