import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { TutoresService } from "app/services/tutores.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, of } from "rxjs";
import { switchMap, takeUntil, finalize } from "rxjs/operators";

export const CREATE_TUTOR_MODAL = "CREATE_TUTOR";

@Component({
  selector: "app-create-tutor",
  templateUrl: "./create-tutor.component.html",
  styleUrls: ["./create-tutor.component.css"],
})
export class CreateTutorComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  public create$ = () =>
    of({}).pipe(
      switchMap(() => {
        if (this.form.invalid) throw "Formulario inválido.";
        this.requesting = true;
        return this._tutores.add(this.form.value);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => (this.requesting = false))
    );

  public update$ = () =>
    of({}).pipe(
      switchMap(() => {
        if (this.form.invalid) throw "Formulario inválido.";
        this.requesting = true;
        return this._tutores.update({ ...this.form.value, id: this.data.id });
      }),

      takeUntil(this.onDestroy$),
      finalize(() => (this.requesting = false))
    );

  //CONFIG

  //STATUS
  public loading: boolean;
  public requesting: boolean;

  //DATA
  public data: any;
  public sexos = ["Masculino", "Femenino"];

  //FORMS
  public form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _tutores: TutoresService
  ) {}

  get disabled() {
    return this.form.invalid || this.requesting;
  }

  ngOnInit() {
    this.initForm();
    this.initListeners();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForm() {
    this.form = this._fb.group({
      nombre: [null, [Validators.required]],
      apellidos: [null, [Validators.required]],
      sexo: [null, [Validators.required]],
      nacionalidad: [null],
      email: [null, [Validators.required]],
      telefono: [null],
      celular: [null, [Validators.required]],
      codigoPostal: [null],
      direccion: [null],
      ciudad: [null],
      estado: [null],
    });
  }

  initListeners() {}

  //REQ DATA
  getData() {
    const { id } = this._modal.get(CREATE_TUTOR_MODAL).getData();
    if (!id) return;
    this.loading = true;
    this._tutores
      .get({ where: { id } })
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        ([data]) => {
          this.data = data;
          this.form.patchValue(data);
        },
        (_) => this._toast.errorToastr("No se encontró el tutor")
      );
  }

  onSubmit() {
    if (this.data) this.update();
    else this.create();
  }

  create() {
    this.create$().subscribe(
      (data) => {
        this._toast.successToastr("Tutor creado correctamente");
        this.close(data);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  update() {
    this.update$().subscribe(
      ([data]) => {
        this._toast.successToastr("Tutor editado correctamente");
        this.close(data);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  close(created?: any) {
    this._modal
      .get(CREATE_TUTOR_MODAL)
      .removeData()
      .setData({ created })
      .close();
  }

  //UTILS
  invalidControl(control: AbstractControl) {
    if (!control) return;
    return control.invalid && control.touched;
  }
}
