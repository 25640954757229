import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AlumnosService } from "app/services/alumnos.service";
import { GruposService } from "app/services/grupos.service";
import { PaquetesService } from "app/services/paquetes.service";
import { TutoresService } from "app/services/tutores.service";
import * as moment from "moment";
import { ToastrManager } from "ng6-toastr-notifications";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subject, concat, of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { defineLocale } from "ngx-bootstrap/chronos";
import { esLocale } from "ngx-bootstrap/locale";
import { PatrocinadoresService } from "app/services/patrocinadores.service";
import { DomSanitizer } from "@angular/platform-browser";
import { UploadedFilesService } from "app/services/uploaded-files.service";
import { InscripcionesService } from "app/services/inscripciones.service";
import {
  CREATE_TUTOR_MODAL,
  CreateTutorComponent,
} from "app/components/catalogos/tutores/components/create-tutor/create-tutor.component";
defineLocale("es", esLocale);

export const CREATE_ALUMNO_MODAL = "CREATE_ALUMNO";

@Component({
  selector: "app-create-alumno",
  templateUrl: "./create-alumno.component.html",
  styleUrls: ["./create-alumno.component.css"],
})
export class CreateAlumnoComponent implements OnInit, OnDestroy {
  @ViewChild("imageInput") imageInput: ElementRef<HTMLInputElement>;

  private readonly onDestroy$ = new Subject<void>();

  public create$ = () =>
    of({}).pipe(
      switchMap(() => {
        if (
          this.form.invalid ||
          (this.inscripcionControl.value && this.inscripcionForm.invalid)
        )
          throw "Formulario inválido.";
        if (!this.file) throw "Seleccione una foto.";
        this.requesting = true;
        return this._uploadedFiles.create(this.file, "alumnos");
      }),
      switchMap(([{ id }]: any) =>
        this._alumnos.add({ ...this.form.value, foto: id })
      ),
      switchMap(({ id: alumno }: any) => {
        if (this.inscripcionControl.value)
          return this._inscripciones.add({
            ...this.inscripcionForm.getRawValue(),
            alumno,
          });
        else return of(alumno);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.requesting = false;
      })
    );

  public update$ = () =>
    of({}).pipe(
      switchMap(() => {
        if (this.form.invalid) throw "Formulario inválido.";
        if (!this.imagen) throw "Seleccione una foto.";
        this.requesting = true;
        if (this.file) return this._uploadedFiles.create(this.file, "alumnos");
        else return of([{}]);
      }),
      switchMap(([{ id }]: any) => {
        const data = this.form.value;
        if (id) data.foto = id;
        return this._alumnos.update(data);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.requesting = false;
      })
    );

  //CONFIG
  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-default",
    dateInputFormat: "DD/MM/YYYY",
  };

  public file: File;
  public imagen: string;

  //STATUS
  public loading: boolean;
  public requesting: boolean;

  //DATA
  public data: any;
  public paquetes: any[] = [];
  public sexos = ["Masculino", "Femenino"];

  public tutores$: any;
  public tutoresLoading = false;
  public tutoresSearch$ = new Subject<string>();

  public grupos$: any;
  public gruposLoading = false;
  public gruposSearch$ = new Subject<string>();

  public patrocinadores$: any;
  public patrocinadoresLoading = false;
  public patrocinadoresSearch$ = new Subject<string>();

  //FORMS
  public form: FormGroup;

  public inscripcionForm: FormGroup;

  public inscripcionControl: FormControl;

  constructor(
    private _fb: FormBuilder,
    private _modal: NgxSmartModalService,
    private _toast: ToastrManager,
    private _locale: BsLocaleService,
    private _sanitizer: DomSanitizer,
    private _alumnos: AlumnosService,
    private _paquetes: PaquetesService,
    private _tutores: TutoresService,
    private _grupos: GruposService,
    private _patrocinadores: PatrocinadoresService,
    private _uploadedFiles: UploadedFilesService,
    private _inscripciones: InscripcionesService
  ) {}

  get disabled() {
    return this.form.invalid || this.requesting;
  }

  ngOnInit() {
    this._locale.use("es");
    this.initForm();
    this.initListeners();
    this.getPaquetes();
    this.getTutores();
    this.getGrupos();
    this.getPatrocinadores();
    this.getData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initForm() {
    this.form = this._fb.group({
      nombre: [null, [Validators.required]],
      apellidos: [null, [Validators.required]],
      fechaNacimiento: [null, [Validators.required]],
      sexo: [null, [Validators.required]],
      email: [null],
      telefono: [null],
      celular: [null],
      direccion: [null],
      tutores: [[], [Validators.required]],
      patrocinador: [null],
      //
      condicionMedica: [null],
      tipoTratamiento: [null],
      alergiasConocidas: [null],
      lesionesPreexistentes: [null],
      medicoCabecera: [null],
      operaciones: [null],
      fracturasOseas: [null],
      telefonoMedico: [null],
      nombreMedicamentos: [null],
      tomaMedicamentos: [null],
      medicamentosCampus: [null],
      club: [null],
      federado: [null],
      altura: [null],
      peso: [null],
    });
    this.inscripcionForm = this._fb.group({
      fecha: [null, [Validators.required]],
      grupo: [null, [Validators.required]],
      paquete: [null, [Validators.required]],
    });
    this.inscripcionControl = this._fb.control(true, []);
  }

  initListeners() {
    this.onFechaNacimientoChange();
  }

  onFechaNacimientoChange() {
    this.form.controls["fechaNacimiento"].valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((value) => {
          if (!value) return of(null);
          const edad = moment().diff(moment(value), "years");

          return this._grupos.get({
            where: {
              status: "activo",
              lleno: false,
              edadMin: { "<=": edad },
              edadMax: { ">=": edad },
            },
            sort: "nombre ASC",
          });
        })
      )
      .subscribe((value) => {
        if (!value) return;
        this.getGrupos(value.map((value) => ({ ...value, sugerido: true })));
        if (value.length)
          this.inscripcionForm.controls["grupo"].setValue(value[0].id);
      });
  }

  //REQ DATA
  getData() {
    const { id } = this._modal.get(CREATE_ALUMNO_MODAL).getData();
    if (!id) return;
    this.loading = true;
    this._alumnos
      .get({ where: { id } })
      .pipe(
        takeUntil(this.onDestroy$),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        ([data]) => {
          this.data = data;
          this.form.patchValue({
            ...data,
            tutores: data.tutores.map(({ id }) => id) || [],
            patrocinador: data.patrocinador
              ? data.patrocinador.id || null
              : null,
            fechaNacimiento: new Date(data.fechaNacimiento),
          });
          if (data.foto) this.getFoto();
          this.getTutores(data.tutores);
          if (data.patrocinador) this.getPatrocinadores([data.patrocinador]);
        },
        (_) => this._toast.errorToastr("No se encontró el alumno")
      );
  }

  getFoto() {
    this._uploadedFiles
      .getFile(this.data.foto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: any) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.imagen = <any>(
            this._sanitizer.bypassSecurityTrustResourceUrl(
              <string>event.target.result
            )
          );
        };
        reader.readAsDataURL(data);
      });
  }

  getPaquetes() {
    this._paquetes
      .get({ status: "activo" })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((paquetes: any[]) => (this.paquetes = paquetes));
  }

  getTutores(init: any[] = []) {
    this.tutores$ = concat(
      of(init), // default items
      this.tutoresSearch$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => (this.tutoresLoading = true)),
        switchMap((contains) =>
          this._tutores
            .get({
              or: [{ nombre: { contains } }, { apellidos: { contains } }],
              status: "activo",
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.tutoresLoading = false))
            )
        )
      )
    );
  }

  getGrupos(init: any[] = []) {
    this.grupos$ = concat(
      of(init), // default items
      this.gruposSearch$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => (this.gruposLoading = true)),
        switchMap((contains) =>
          this._grupos
            .get({
              where: {
                nombre: { contains },
                status: "activo",
                lleno: false,
              },
              sort: "nombre ASC",
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.gruposLoading = false))
            )
        )
      )
    );
  }

  getPatrocinadores(init: any[] = []) {
    this.patrocinadores$ = concat(
      of(init), // default items
      this.patrocinadoresSearch$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => (this.patrocinadoresLoading = true)),
        switchMap((contains) =>
          this._patrocinadores
            .get({
              where: {
                nombre: { contains },
                status: "activo",
              },
              sort: "nombre ASC",
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.patrocinadoresLoading = false))
            )
        )
      )
    );
  }

  onSubmit() {
    if (this.data) this.update();
    else this.create();
  }

  create() {
    this.create$().subscribe(
      (data) => {
        this._toast.successToastr("Alumno creado correctamente");
        this.close(data);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  update() {
    this.create$().subscribe(
      ([data]) => {
        this._toast.successToastr("Alumno editado correctamente");
        this.close(data);
      },
      (error) => this._toast.errorToastr(error)
    );
  }

  openCreateTutor(id?: boolean) {
    this._modal
      .create(CREATE_TUTOR_MODAL, CreateTutorComponent, {
        dismissable: false,
        closable: false,
      })
      .removeData()
      .setData({ id })
      .open()
      .onAnyCloseEventFinished.pipe(takeUntil(this.onDestroy$))
      .subscribe(({ _data }) => {
        if (_data.created) {
          this.form.controls["tutores"].setValue([
            ...this.form.controls["tutores"].value,
            _data.created.id,
          ]);
          this.getTutores([_data.created]);
        }
      });
  }

  close(created?: any) {
    this._modal
      .get(CREATE_ALUMNO_MODAL)
      .removeData()
      .setData({ created })
      .close();
  }

  //IMAGE
  selectImage() {
    this.imageInput.nativeElement.click();
  }

  setImage(e) {
    const files: FileList = e.target.files;
    if (!files.length) return;
    const file = files.item(0);
    this.file = file;
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagen = <any>(
        this._sanitizer.bypassSecurityTrustResourceUrl(
          <string>event.target.result
        )
      );
    };
    reader.readAsDataURL(file);
  }

  //UTILS
  invalidControl(control: AbstractControl) {
    if (!control) return;
    return control.invalid && control.touched;
  }
}
