import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "../globals";
@Injectable({
  providedIn: "root",
})
export class UploadedFilesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  create(file: File, path: string) {
    const fd = new FormData();
    fd.append("path", path);
    fd.append("file", file);
    return this.http.post(`${this.globals.apiUrl}/uploaded-files`, fd);
  }

  getFile(id: number) {
    return this.http.get(`${this.globals.apiUrl}/uploaded-files/${id}`, {
      responseType: "blob",
    });
  }
}
